<template>
    <v-main>
        <div v-if="checkout && $axios.getLocal('session')" class="animate__animated animate__fadeIn animate__delay-1s">
            <headerC />
                <div class="container-n" :style="color ? 'background-color: ' + color : 'white'">
                    <slot></slot> 
                </div>
            <footerC />
            <Barra/>
        </div>

        <div v-else class="animate__animated animate__fadeIn animate__delay-1s">
            <headerD />
                <div class="container-n" :style="color ? 'background-color: ' + color : 'white'">
                    <slot></slot> 
                </div>
            <footerD />
            <Barra/>
        </div>
        
    </v-main>
</template>

<script>
    export default {
        name: 'Layout',
        props: ['checkout', 'color'],
        components: {
            headerD: () => import('@/layout/components/header.vue'),
            footerD: () => import('@/layout/components/footer.vue'),
            headerC: () => import('@/layout/components/header-checkout.vue'),
            footerC: () => import('@/layout/components/footer-checkout.vue'),
            Barra : () => import('@/components/barraInferior.vue'),
        },
    }
</script>

<style>
.container-n {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
</style>